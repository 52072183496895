import { arrayOf, oneOfType, shape, string } from "prop-types";

import CTABlock, { CTABlockType } from "~components/CTABlock";
import USPBlock, { USPBlockType } from "~components/USPBlock";
import DynamicContent, { DynamicContentTypes } from "~modules/DynamicContent";
import HomepageHero from "~modules/HomepageHero";
import { ImageType } from "~types";

import classes from "./HomepageTemplate.module.scss";

const HomepageTemplate = ({ Homepage }) => {
  const { id, title, titleEmphasis, intro, heroImage, usps, cta } = Homepage;

  return (
    <>
      <HomepageHero
        title={title}
        titleEmphasis={titleEmphasis}
        intro={intro}
        heroImage={heroImage?.[0]}
      />
      <div className={classes.container}>
        {usps?.length > 0 ? (
          <div className={classes.grid}>
            <div className={classes.column_usps}>
              <USPBlock data={usps} />
            </div>
          </div>
        ) : (
          <div className={classes.offset} />
        )}
      </div>

      <DynamicContent parentPageId={id} content={Homepage.content} />

      {!!cta.length && (
        <div className={classes.container}>
          <div className={classes.grid}>
            <div className={classes.column}>
              <CTABlock {...cta[0]} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

HomepageTemplate.propTypes = {
  Homepage: shape({
    id: string,
    title: string,
    titleEmphasis: string,
    heroImage: arrayOf(shape(ImageType)),
    metaTitle: string,
    metaDescription: string,
    intro: string,
    usps: arrayOf(shape(USPBlockType)),
    cta: arrayOf(shape(CTABlockType)),
    content: arrayOf(oneOfType(DynamicContentTypes)),
  }),
};

export default HomepageTemplate;
